.screenMaxWidth, .bannerContainer, .carouselStyle {
    max-width: 370vh;
    height: 541px;
}

.carouselStyle {
    background-repeat: no-repeat;
    background-size: auto;
    text-align: left;
}

.text-align-vertical-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 541px;
}

.bannerSearchInputField {
    width: 378px;
    height: 45px;
    border: none;
    background-color: #ffffff;
    /* padding: 0 0 0 20px; */
    font-size: 13px;
    color: #000;
    text-align: center;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-left: 1%;
}

.bannerSearchIcon {
    width: 45px;
    height: 45px;
    background-color: rgb(22, 64, 124);
    border: none;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.QWatermark{
  background-image: url('../../public/assets/images/home/Union.png');
  background-repeat: no-repeat;
  background-size: 240px;
    background-position: 36% 55%;
}

.banner3cards {
    position: relative;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.bannerCardCustom{
    width: 250px;
    height: 120px;
}

.text-success-color {
    color: #28a745;
}

/* media for mobile */
@media (max-width: 767px) {
    .bannerSearchInputField{
        width: 60%;
        margin-left: 4%;
    }
    .QWatermark{
        background-image: none;
    }
    .banner3cards {
        gap: 10px;
    }
    .bannerCardCustom{
        width: 120px;
        height: 100px;
    }
    .fontCustomStyleTitleBannerPart{
        font-size: 20px;
    }
    .fontCustomStyleDescriptionBannerPart{
        font-size: 10px;
    }
}

/* media for tablet */
@media (min-width: 768px) and (max-width: 1024px) {
    .QWatermark{
        background-position: 350px 55%;
    }
}