.searchInput{
  width: 100%;
  padding: 7px;
  outline: none;
  border: none;
  border-bottom: 3px solid lightblue;
}

.searchInput::placeholder{
  color:black !important;
}


.about-hero-section{
  width: 100%;
	height: 60vh;
	display: flex;
	padding-top: 50;
	padding-bottom: 50;
	justify-content: center;
	overflow: hidden;
	object-fit: cover;
	background-repeat: no-repeat;
	background-size: cover;
  /* filter: contrast(0.8); */
  background-position: center;
  background-attachment: fixed;
}



.about-main-section-partner-img{
  width: 140px;
	height: 140px;
	border-radius: 10px;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
	margin-bottom: 1rem;
	object-fit: contain;
	padding: 3px;
  mix-blend-mode: darken;
}

.about-heading{
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-our-mission-section{
  background-color: #0056d2;
	width: 100%;
	margin-top:2rem;
	height: 80vh;
	display: flex;
	padding-top: 50;
	padding-bottom: 50;
	justify-content: center;
	overflow: hidden;
	object-fit: cover;
	background-repeat: no-repeat;
	background-position: center;
  filter: brightness(0.9);
  background-attachment: fixed;
}
.qacademy-semi-heading{
  text-align: center;
   color: #fff;
    margin-top: 20px;
    line-height: 4rem; 
}

@media (max-width: 990px){
  .qacademy-semi-heading{
    font-weight: 100;
    line-height: 2.5rem;
  }
  .about-hero-section{
    height: 80vh;
  }
  .about-main-section .about-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 100%;
    margin-top: 0;
  }
  
}
.AboutBanner {
  background-image: url("/src/images/AboutImgQ.png");
  background-size: cover;
  background-position: center  center;
  background-repeat: no-repeat;
  height: 541px;
  
  /* Add other styles as needed */
}