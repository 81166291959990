.footer_socialmedia{
    --bs-list-group-border-color :none;
  }
  
  .WaterM {
    background-image: url("/src/images/waterMark.png");
    background-size: cover;
    background-position: center  center;
    
  }

/* media query for mobile view */

@media only screen and (max-width:600px){
  .bannerTwoset::after {
    display: none; /* Hide the second image on mobile view */
  }
  .BannerOne::after {
    display: none; /* Hide the second image on mobile view */
  }
}

.bannerTwoset{
  position: relative;
  height: 541px;
  background-image: url("/src/images/image2.png"), url("/src/images/que.png");
  background-size: fit;
  background-position: center;
  background-repeat: no-repeat;
}
.bannerTwoset::after {
  content: "";
  position: absolute;
  top:15%;
  left:30%;
  width:300px;
  height: 300px;
  background-image: url("/src/images/que.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80%;
  z-index: -1;
}

  .BannerOne {
    position: relative;
    height: 541px;
    background-image: url("/src/images/BannerOne_new.png"), url("/src/images/que.png");
    background-size: fit;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .BannerOne::after {
    content: "";
    position: absolute;
    top: 50%; /* Adjust the vertical position in percentage values */
    left: 50%; /* Adjust the horizontal position in percentage values */
    width: 300px;
    height: 300px;
    background-image: url("/src/images/que.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 30% 40%; /* Adjust the background position in percentage values */
    background-attachment: fixed;
    transform: translate(-90%, -50%); /* Center the image */
  }
  
  
  
  /* .BannerOne {
    background-image: url("/src/images/BannerOne_new.png"),url("/src/images/waterMark.png");
    background-size: cover;
    background-position: center  center;
    height: 541px;
  } */
  .smallQ {
    background-image: url("/src/images/small-q.png");
    height: 140px;
    width: 140px;
    right: 0px;
    top: -73px;
    background-size: cover;
    background-position: center  center;
    /* background-color: red;     */
    /* Add other styles as needed */
  }
  .individualQ {
    /* background-image: url("/src/images/small-q.png"); */
    position: absolute;
    height: 100px;
    width: 100px;
    top: 0px;
    right: 0px;
    background-size: cover;
    background-position: center center;
   /* Add other styles as needed */
  }
  .individualQ2 {
    /* background-image: url("/src/images/small-q.png"); */
    position: absolute;
    height: 100px;
    width: 100px;
    top: 0px;
    left: 0px;
    background-size: cover;
    background-position: center center;
    /* Add other styles as needed */
  }
  .individualQ3 {
    /* background-image: url("/src/images/small-q.png"); */
    position: absolute;
    height: 100px;
    width: 100px;
    top: 0px;
    right: 0px;
    background-size: cover;
    background-position: center center;
   /* Add other styles as needed */
  }
  /* .bannerTwoset {
    background-image: url("/src/images/Second_Banner.png");
    background-size: cover;
    background-position: center  center;
    background-repeat: no-repeat;
    height: 541px;
  } */
  .full-width-modal {
    max-width:100%;
  }
 

  .slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: '';
    color: rgb(246, 246, 246);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.textColorBlack {
  color: #000000;

}
.BGIMG{
  background-image: url("/src/images/coursespgBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: 25rem;
}
.union{
 
  background-image: url("/src/images/Union.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 150px;
  height: 150px;
}


.onHoverTextColorChange:hover {
  color: rgb(15, 117, 188) !important;
  transition: 0.5s;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  
}

.hover-effect:hover {
  background-color: #f0f0f0; /* Change this to the desired background color on hover */
  /* Add other styles you want to apply on hover */
}


 .GobalSideGap{
  padding-left :'35px';
  padding-right :'35px';
}