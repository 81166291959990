
.CardHover{
    transition: box-shadow 0.3s ease;
    box-shadow: 2px 1px 10px 0px #e3e1e1;
    height: 330px;

}
.CardHover:hover {
    /* box-shadow: 0 0 10px rgb(0, 0, 0); */
    box-shadow:2px 5px 10px 2px rgba(197, 214, 224, 0.582);
    transform: scale(1.05);
    transition: 0.5s;
}

/* on hover shadow effect */
.cardShadow:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    transition: 0.5s;
}

.onHoverTextColorChange:hover {
    /* background-color: #0c71ff14 !important; */
    color: #0056d2;
    transition: 0.5s;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
}

.bg-white {
    background-color: #fff !important;
}

.feedback-component {
    display: none;
  }

.footerLi{
    padding: 2px;
}