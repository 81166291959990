h2,
h5,
p,
h4 {
  color: #161616;
}

#card {
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

#card,
.border {
  border-width: 1px;
  border-style: solid;
  border-color: #c2c2c2;
  border-radius: 5px;
}

.contain-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-left-align-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bannerContent,
.text-left-vertical-center {
  text-align: left;
  display: flex;
  align-items: center;
}

.accordionHeader {
  height: fit-content;
}

.blogViewAllHeader {
  background-color: #0f75bc;
  max-width: 1717.41px;
  height: 25rem;
  background-image: url("../../images/Union.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 360px;
  background-position: 32%;
}

.bannerContent {
  height: 25rem;
  max-width: 55%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.title {
  font-size: 1.8rem;
  font-weight: 500;
  color: #0f75bc;
}

.description {
  max-height: 150px;
  overflow: hidden;
}

.link {
  color: #0f75bc;
  text-decoration: underline;
}

#basic-nav-dropdown {
  margin-top: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #28282875;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .bannerContent {
    max-width: 90%;
  }

  .title {
    font-size: 1.4rem;
  }



  .description {
    max-height: 100px;
  }

}