.roboto{
  font-family: 'Roboto', sans-serif;
}
.left-aligned-placeholder::placeholder{
  text-align: left;
}
.stretched-link:hover{
  color: #0F75BC !important;
}

.bannerStyleofViewAllCourses{
  
  text-align: left !important;
}

/* media quries */

@media (max-width: 576px) {
  .custom-overflow{
    overflow-x: hidden;
  }
}

@media (min-width: 448px) and (max-width: 1023px) {
  .bannerStyleofViewAllCourses{
    width: 100%;
  }
}
