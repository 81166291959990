.theme-color {
  background-color: #0056d2 !important;
}

.fill-body {
  fill: #0056d2 !important;
}

.display-6 {
  color: #000000 !important;
}

.lead {
  color: #ffff !important;
}

.color-w {
  color: #ffff !important;
}

.btn-danger-soft {
  background-color: #ffffff !important;
  color: #0056d2 !important;
}

.btn-danger-soft:hover {
  background-color: #ffffff !important;
  color: #0056d2 !important;
}

.section-one img {
  border-radius: 5px;
}

/* #course-pills-tab {
    background-color: #0056d2 !important;
  } */

.card-580 {
  min-height: 580px;
}

.card-color-light-nb {
  background-color: #0056d269 !important;
}

.card-color-dark-nb {
  background-color: #0058d288 !important;
}

.arrow-hover:hover [data-controls=next] {
  right: 28px !important;
}

.text-success {
  --bs-text-opacity: 1;
  /* color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; */
  color: #0056d2 !important;
}

.btn-success-soft {
  color: #fff;
  background-color: #0056d2;
}

.btn-success-soft:hover {
  color: #fff !important;
  background-color: #0056d2 !important;
}

.effect div:hover {
  -webkit-box-shadow: 3px 13px 11px blue !important;
  -moz-box-shadow: 3px 13px 11px pink !important;
  box-shadow: 3px 13px 11px rgb(139, 136, 134) !important;
  opacity: 2 !important;
  cursor: pointer !important;
}

.bg-primarys {
  --bs-bg-opacity: 1;
  background-color: #0056d2 !important;
}

/* 
  .pills-btn:hover,
  .pills-btn.active {
    background-color: #ffff !important;
    color: #0056d2 !important;
    box-shadow: 3px 3px 5px #3EAAF2 !important;
  } */

.select-btn {
  background-color: #ffffff !important;
  color: rgb(146, 35, 35) !important;
  box-shadow: 3px 3px 5px #3EAAF2 !important;
}

pills-btn {
  color: #3EAAF2 !important;
  background-color: white !important;
}

pills-btn:hover,
.pills-btn:active {
  background-color: #3EAAF2 !important;
  color: rgb(146, 35, 35) !important;
}

/* CSS for the partner logos section */
.partners-section {
  background-color: #f9f9f9;
  padding: 10px 0;
}

.partners-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.partners-logos .carousel {
  display: flex;
  animation: carousel-slide 15s infinite linear;
}

@keyframes carousel-slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - 10px));
  }
}


.partners-logos img {
  max-width: auto;
  max-height: 90px;
  margin: 15px;

}

#logo {

  height: 60px;
  /* Maintain the aspect ratio */
}



/* Media query for responsive layout */
@media (max-width: 576px) {
  .partners-logos {
    flex-wrap: wrap;
  }

  .partners-logos img {
    margin: 5px;
    max-height: 60px;
    /* Adjust the height as needed */
  }
}

.col-xl-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100% !important;
}

.element-style {
  margin-bottom: 30px !important;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgb(255 0 0 / 75%) !important;
}

/* delete line 176 to 204 is used for home page dropdown*/
.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.arrow-button i {
  margin-right: 0.5rem;
}

.arrow-button span {
  display: inline-block;
}

.arrow-button::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  margin-left: 0.5rem;
}

.button {
  background-color: #0056d2 !important;
  border-radius: 4px;
  color: white;
  padding: 2pxv 2px 2px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}