.roadmapMainContainer {
  max-width: fit-content;
  align-self: center;
}

.headerContainer {
  background-color: "rgb(243, 243, 243)";
  max-width: 1717.41px;
  height: fit-content;
  background-image: url("../../../public/assets/images/raodmap/paper-analysis.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 50px 0px;
  margin: auto;
}
.landingPageIndexHeaderContainer {
  background-image: url("../../../public/assets/images/LandingPage/VectorSmartObject5.png");
}
,
.HeaderContent {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.text-primary-color {
  color: #1142c6;
}

.capitalize {
  text-transform: uppercase;
}

.button {
  background-color: #1142c6;
  border-radius: 50px;
  padding: 20px 50px;
}

.btn-black {
  border-radius: 50px;
  padding: 20px 50px;
  background-color: black;
}

.body {
  padding: 50px 0px;
}

.big-icon {
  font-size: 2rem;
  background-color: black;
  padding: 15px;
  border-radius: 50%;
  color: white;
  font-weight: 600;
}

.aboutWebinarContainer {
  max-height: 95vh;
  /* min-height: 65vh; */
}

.aboutHost {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../../public/assets//images/roadmapBanner2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 0px;
  overflow: hidden;
}

.deepakGourImage {
  background-image: url("../../../public/assets/images/Deepakumar.png");
  background-size: 32%;
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
}

.hostInfo {
  padding: 50px 0px;
  background-color: #1142c6;
  padding: 30px 50px;
  border-radius: 40px;
  max-width: 80%;
}

.whyJoin {
  padding: 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.yJTitle {
  width: 45%;
  font-size: 4rem;
}

.infoCard {
  background-color: #1142c6;
  padding: 20px 10px;
  border-radius: 20px;
  width: 270px;
  min-height: fit-content;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  min-height: 350px;
}

.iconImage {
  height: 100px;
  width: fit-content;
  margin-bottom: 20px;
}

.infoCardDivider {
  background-color: white;
  width: 50%;
  height: 2px;
}

#text-input-custom-style {
  border-radius: 50px;
  padding: 20px 50px;
  border: 1px solid #000000;
  font-size: x-large;
  color: #000;
}

.certification {
  background-image: url("../../../public/assets/images/roadmapCertificateBackgroundBanner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 50px 0px;
}

.certificationTitle {
  font-size: 5rem;
  color: white;
  margin-bottom: 20px;
  width: 10cap;
  line-height: 80px;
  padding-bottom: 50px;
  border-bottom: #fff solid 5px;
}

.helpLowerImage {
  background-image: url("../../../public/assets/images/roadmapHelpingLargeScreen1_2.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 300px 0px;
}
.helpRow {
  text-align: center;
  background-image: url("../../../public/assets/images/roadmapHelping1_1.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 443px;
}
.infoCardTitle {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 202px;
}

.registerFormImage {
  background-image: url("../../../public/assets/images/LandingPage/ladki.png");
  background-size: 13rem;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 100%;
  padding-top: 3rem;
}

.liveLogo {
  width: 250px;
}

.faq {
  background-image: url("../../../public/assets/images/raodmap/office-workers-using-finance-graphs.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sideRib-registerForm {
  background-image: url("../../../public/assets/images/raodmap/sideRib.png");
  background-size: 8%;
  background-repeat: no-repeat;
  background-position: 100% 68%;
}
.sideRib-aboutWebinar {
  background-image: url("../../../public/assets/images/raodmap/sideRibLeft.png");
  background-size: 8%;
  background-repeat: no-repeat;
  background-position: 0% 68%;
}

.stickyButton {
  display: none;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: auto;
  /* border-radius: 0px 100px 20px 100px; */
  padding: 10px 15px;
}

/* mobile */
@media screen and (max-width: 767px) {
  .hideOnMobile {
    display: none;
  }
  .hostInfo {
    max-width: 100%;
  }
  .yJTitle {
    width: 100%;
    font-size: 3rem;
  }
  .deepakGourImage {
    background-image: none;
  }
  .helpLowerImage {
    background-image: url("../../../public/assets/images/roadmapHelpingSmallScreen1_2.png");
    padding: 20px 10px;
    background-size: auto;
  }
  .helpRow {
    position: relative;
    height: fit-content;
  }
  .certificationTitle {
    font-size: 3rem;
    line-height: 50px;
  }
  .certification {
    min-height: fit-content;
  }
  .registerFormImage {
    background-image: none;
  }
  .aboutWebinarContainer {
    max-height: fit-content;
  }
  .stickyButton {
    display: flex;
    transform: translate(-10px, -10px);
  }
}

/* tabs */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .yJTitle {
    width: 60%;
    font-size: 3rem;
  }
  .deepakGourImage {
    background-size: 50%;
    background-position-x: 97%;
    background-position-y: 99.25%;
  }
  .helpRow {
    position: relative;
    height: fit-content;
  }
  .registerFormImage {
    background-image: none;
  }
}
