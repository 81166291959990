.roadmapMainContainer{
    max-width: fit-content;
    align-self: center;
  }
  
  .liveLogo{
    width: 250px;
  }
  .LheaderContainer {
    height: fit-content;
    background-image: url("../../../public/assets/images/LandingPage/VectorSmartObject.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding: 50px 0px;
  }
  
  .HeaderContent {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
      flex-direction: column;
  }
  
  .text-primary-color {
    color: #1855e9;
  }
  
  .capitalize {
    text-transform: uppercase;
  }
  
  .button {
    background-color: #1855e9;
    border-radius: 50px;
    padding: 20px 50px;
  }
  
  .btn-black {
    border-radius: 50px;
    padding: 20px 50px;
    background-color: black;
  }
  
  .body {
    padding: 50px 0px;
  }
  
  .big-icon {
    font-size: 2rem;
    background-color: black;
    padding: 15px;
    border-radius: 50%;
    color: white;
    font-weight: 600;
  }
  
  .LaboutHost {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("../../../public/assets//images/LandingPage/VectorSmartObject3.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 0px;
    overflow: hidden;
  }
  
  .LdeepakGourImage {
   background-image: url("../../../public/assets/images/LandingPage/0.png");
    background-size: 32%;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 100%;
    width: 100%;
  }
  
  .LhostInfo {
    background-color: #fff;
    padding: 30px 50px;
    border-radius: 40px;
    max-width: 80%;
  }
  
  .whyJoin {
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  /* .yJTitle {
      width: 45%;
      font-size: 3rem;
      line-height: 20px;
  } */
  
  .infoCard {
    background-color: #1855e9;
    /* margin: 10px 10px; */
    padding: 20px 10px;
    border-radius: 20px;
    width: 270px;
    min-height: fit-content;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 3rem;
    min-height: 350px;
  }
  
  .iconImage {
      height: 100px;
      width: fit-content;
      margin-bottom: 20px;
  }
  
  .infoCardDivider {
    background-color: white;
    width: 50%;
    height: 2px;
  }
  
  #text-input-custom-style {
    border-radius: 50px;
    padding: 20px 50px;
    border: 1px solid #000000;
    font-size: x-large;
    color: #000;
  }
  .Lcertification {
    background-image: url("../../../public/assets/images/LandingPage/VectorSmartObject7.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    /* min-height: 50rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px 0px;
  }
  
  .LcertificationTitle {
    font-size: 5rem;
    color: #1855e9;
    margin-bottom: 20px;
    width: 10cap;
    line-height: 80px;
    border-bottom: #1855e9 solid 5px;
  }
  
  .helpTitle {
    /* font-size: 3rem; */
    /* margin-bottom: 20px; */
    /* width: 20cap; */
    line-height: 20px;
  }
  
  .LhelpLowerImage {
    background-image: url("../../../public/assets/images/LandingPage/young-mixed-race-woman-going-school-blue-wall-holding-blank-space-palm-insert-ad.png");
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: 30% bottom;
    padding: 30vh 0px 0px 0px;
    height: 100vh;
  }
  .LhelpRow {
  text-align: center;
  background-image: url("../../../public/assets/images/LandingPage/VectorSmartObject5.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 443px;
  }
  .infoCardTitle{
    justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 202px;
  }
  .registerFormImage{
    background-image: url("../../../public/assets/images/LandingPage/ladki.png");
    /* background-size: 12rem; */
    background-size: 18rem;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 100%;
  }

  .Lfaq{
    background-image: url("../../../public/assets/images/LandingPage/VectorSmartObject.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }

  .sideRib-aboutWebinar{
    background-image: url("../../../public/assets/images/raodmap/sideRibLeft.png");
    background-size: 8%;
    background-repeat: no-repeat;
    background-position: 0% 68%;
  }
  .sideRib-right{
    background-image: url("../../../public/assets/images/raodmap/sideRib.png");
    background-size: 8%;
    background-repeat: no-repeat;
    background-position: 100% 40%;
  }
  .sideRib-registerForm{
    background-image: url("../../../public/assets/images/raodmap/sideRib.png");
    background-size: 8%;
    background-repeat: no-repeat;
    background-position: 100% 68%;
  }

  .aboutWebinarImage{
    width: 68%;
  }

  .stickyButton{
    display: none;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: auto;
    /* border-radius: 0px 100px 20px 100px; */
    padding: 10px 15px;
    font-size: large;
  }
  

  /* mobile */
  @media screen and (max-width: 767px) {
      .hideOnMobile {
          display: none;
      } 
      .hostInfo {
          max-width: 100%;
      }
      /* .yJTitle {
          width: 100%;
          font-size: 3rem;
      } */
      .LdeepakGourImage {
        background-image: none;
      }
      .LhelpLowerImage {
        padding: 20px 10px;
        background-image: none;
        height: fit-content;
      }
      .LhelpRow {
        position: relative;
        height: fit-content;
      }
      /* .helpTitle {
        width: fit-content;
        font-size: 1.8rem;
        line-height: normal;
        margin-bottom: 40px;
      } */
      .LcertificationTitle{
        font-size: 3rem;
        line-height: 50px;
      }
      .certification{
        padding: 20px 10px;
        min-height: fit-content;
      }
      .registerFormImage{
        background-image: none;
      }
      .LhostInfo {
        max-width: fit-content;
      }
      .stickyButton{
        display: flex;  
      }
  }
  
  
  /* tabs */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
      .yJTitle {
          /* width: 60%;
          font-size: 3rem; */
          line-height: 40px;
      }
      .deepakGourImage {
        background-size: 50%;
        background-position-x: 97%;
        background-position-y: 99.25%;
        padding-bottom: 20px;
      }
      .LhelpRow {
        position: relative;
        height: fit-content;
      }
      .LhelpLowerImage {
        padding: 20px 10px;
        height: fit-content;
        background-position: 20% 100%;
      }
      .registerFormImage{
        background-image: none;
      }
      .aboutWebinarImage{
        width: 100%;
      }
      .helpTitle{
        line-height: 40px;
      }
      
  }
  