.LP-roadmapMainContainer {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .LP-headerButton {
    width: "fit-content";
    font-size: 18px !important;
    padding: 20px 16px !important;
  }
  .LP-AboutWebinarButton {
    width: "fit-content";
    font-size: 18px !important;
  }
  .LP-registerNowButton {
    width: "fit-content";
    font-size: 18px !important;
  }
  .LP-SkillsContainer {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1500px) {
  .LP-SkillsContainer {
    margin-top: 50px;
  }
}

.LP-aboutWebinarContainer {
  max-height: fit-content;
}
