/* PartnerView.css */
.partners-section{
  box-sizing: border-box;
  height: 50vh;
  width: 100%;
  padding: 4rem 0;
  padding-top: 5rem;
}

.text-underline-hover{
  text-decoration: none;
  transition: all 2s linear;
}
.text-underline-hover:hover{
  text-decoration: underline;
  cursor: pointer;
}
/* Partner Slider styles */
.partner-slider {
  padding: 20px; /* Add some padding around the slider */
}


/* Adjust the size of the partner logos for different screen sizes */
.img-container img {
  width: 150px; /* Change the width of the logos for larger screens */
  height: 100px; /* Change the height of the logos for larger screens */
  margin: 7px;
  padding: 7px;
  object-fit: contain;
  mix-blend-mode: darken;
  image-rendering: crisp-edges;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  /* Adjust the size of the partner logos for medium screens */
  .partner-slider img {
    width: 120px;
    height: 80px;
  }
  .partners-section{
    height: 60vh;
  }
  .partners-section h4{
    font-size: 2.5rem;
  }
  
  .img-container img:nth-child(2n){
    display: none;
  
  }
}

@media screen and (max-width: 576px) {
  /* Adjust the size of the partner logos for small screens (mobile devices) */
  .partner-slider img {
    width: 80px;
    height: 60px;
  }
}
.Carauselshadow {
 filter: drop-shadow(5px 5px 18px black);
}
.Carauselshadowfeedback{
  filter: drop-shadow(1px 1px 12px black);
 }
 
.partnerCarauselshadow{
   filter: drop-shadow(0px 0px 8px black);
  }
